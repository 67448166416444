import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./routes/client/client.module').then((x) => x.ClientModule),
  },
  // {
  //   path: 'client',
  //   loadChildren: () =>
  //     import('./routes/client/client.module').then((x) => x.ClientModule),
  // },
  // {
  //   path: 'maquina',
  //   loadChildren: () =>
  //     import('./routes/client/client.module').then((x) => x.ClientModule),
  // },
  // {
  //   path: 'loading',
  //   loadChildren: () =>
  //     import('./routes/client/client.module').then((x) => x.ClientModule),
  // },
  {
    path: 'painel',
    loadChildren: () =>
      import('./routes/painel/painel.module').then((x) => x.PainelModule),
  },
];
